import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p style={{
      fontFamily: `Roboto`,
      fontSize: `24px`
    }}>
I am a Full Stack Developer. I build things for computers.
    </p>
    <p style={{
      fontFamily: `Patua One`,
      fontSize: `28px`
    }}>
What am I doing right now?
    </p>
    <p>{`I am working on `}<a parentName="p" {...{
        "href": "https://github.com/SanketDG/extenstart/"
      }}>{`extenstart`}</a>{`, a modern web
extension scaffolding and workflow tool,`}</p>
    <p>{`I have just started `}<a parentName="p" {...{
        "href": "https://github.com/SanketDG/learn-graphs"
      }}>{`learn-graphs`}</a>{`, an interactive
tutorial about graph theory and it's applications.`}</p>
    <p style={{
      fontFamily: `Roboto`,
      fontSize: `18px`
    }}>
— Last Updated 9th November, 2020
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      